<template>
  <a-modal
    title="处理退货区"
    :width="880"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="仓库" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            placeholder="选择仓库"
            allow-clear
            v-decorator="['warehouse_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
            @change="handleCommonWarehouseChange"
          >
            <a-select-option v-for="d in commonWarehouse" :key="d.id">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="库位" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            placeholder="选择库位"
            allow-clear
            v-decorator="['cargo_space_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
            @change="handleCommonWarehouseCargoSpaceChange"
          >
            <a-select-option v-for="d in warehouseCargoSpace" :key="d.id">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="货品" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            placeholder="选择货品"
            allow-clear
            v-decorator="['commodity_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
            @change="handleCommodityChange"
          >
            <a-select-option v-for="d in commodityList" :key="d.id">
              {{ d.batch }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            @change="handleChange"
            v-decorator="['status', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
          >
            <a-select-option
              v-for="(val, key) in this.$Dictionaries.refund_goods_status"
              :key="key"
              :value="key">
              {{ val }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="入库数量" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number
            v-decorator="['to_sales_area_count', {rules: [{ required: true, message: '请输入数量' }]}]" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>

import { warehouse_list } from '@/api/c_wms_warehouse'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import { commodity_list } from '@/api/c_wms_commodity'

export default {
  name: 'DeliverAddressForm',
  components: {
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      map: {},
      common_warehouse_id: 0,
      commonWarehouse: [],
      warehouseCargoSpace: [],
      commodityList: []
    }
  },
  mounted () {
    this.handleCommodityChange()
    this.commonWarehouseData(undefined)
  },
  methods: {
    handleChange (data) {
      console.log(data)
    },
    commonWarehouseData (value) {
      setTimeout(() => {
        warehouse_list({ name: value }).then(({ data }) => {
          this.commonWarehouse = data.entries
        })
      }, 300)
    },
    handleCommonWarehouseChange (value) {
      console.log('handleChange', value)
      this.common_warehouse_id = value
      this.cargoSpaceData()
    },
    cargoSpaceData () {
      this.warehouseCargoSpace = []
      // this.queryParam.wms_supervised_warehouse_cargo_space_id = undefined
      cargo_space_list({ wms_supervised_warehouse_id: this.common_warehouse_id }).then(res => {
        this.warehouseCargoSpace = res.data.entries
      })
    },
    handleCommonWarehouseCargoSpaceChange (value) {
      console.log('handleCommonWarehouseCargoSpaceChange', value)
      // this.queryParam.wms_supervised_warehouse_cargo_space_id = value
    },
    handleCommodityChange () {
      console.log('handleCommodityChange')
      commodity_list({ wms_supervised_warehouse_goods_id: this.model.wms_goods_id }).then(res => {
        this.commodityList = res.data.entries
      })
      // this.queryParam.commodity_id = value
    }
  }
}
</script>
<style>
</style>
