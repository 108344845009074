<template>
  <a-modal
    title="退货"
    :width="680"
    :visible="visible"
    @ok="() => { $emit('ok', this.queryParam) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-row>
          <a-col :span="24">
            <a-form-item label="仓库" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-select
                :not-found-content="fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                placeholder="选择仓库"
                show-search
                allow-clear
                v-decorator="[
                  'warehouse_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                @search="this.warehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="库位" :labelCol="formLayout.labelCol" :wrapperCol="formLayout.wrapperCol">
              <a-select
                option-filter-prop="children"
                :filter-option="filterOption"
                placeholder="库位名称"
                show-search
                allow-clear
                v-decorator="[
                  'cargo_space_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                @search="handleCargoSpaceSearch"
              >
                <a-select-option v-for="d in dataSource_wms_cargo_space" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import debounce from 'lodash/debounce'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { cargo_space_list } from '@/api/c_wms_cargo_space'
import pick from 'lodash.pick'

const fields = ['warehouse_id', 'cargo_space_id']
export default {
  name: 'RefundGoodsForm',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    model: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      fetching: false,
      loading: false,
      queryParam: {},
      warehouse: '',
      cargo_space: '',
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.model)
      this.handleWarehouseSearch(undefined)
      this.handleCargoSpaceSearch(undefined)
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, fields)
      console.log('formData', formData)
      form.setFieldsValue(formData)
      this.queryParam.warehouse_id = data.warehouse_id
      this.queryParam.cargo_space_id = data.cargo_space_id
      this.loading = true
    },
    handleWarehouseSearch (value) {
      this.dataSource_wms_warehouse = []
      this.fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      }).finally(() => {
        this.fetching = false
      })
    },
    handleWmsWarehouseChange (value) {
      this.queryParam.warehouse_id = value
      if (value === undefined) {
        this.handleWarehouseSearch(undefined)
      }
      this.handleCargoSpaceSearch(undefined)
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleCargoSpaceSearch (value) {
      this.dataSource_wms_cargo_space = []
      this.queryParam.cargo_space_id = undefined
      if (!this.queryParam.warehouse_id) {
        return
      }
      cargo_space_list({ warehouse_id: this.queryParam.warehouse_id, name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_cargo_space = result
        if (this.dataSource_wms_cargo_space.length === 1) {
          this.queryParam.cargo_space_id = this.dataSource_wms_cargo_space[0].id
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
